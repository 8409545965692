<template>
  <v-row>
    <v-col sm="12">
      <v-card>
        <v-card-title>
          <v-icon @click="pushRouteToView('users')" class="mr-4" style="cursor: pointer">
            mdi-arrow-left
          </v-icon>
          <v-icon left>mdi-account-group</v-icon>
          Dados do Usuário
          <v-spacer></v-spacer>
          <v-btn outlined
                 v-show="user.username !== '001'"
                 class="btn-del"
                 @click="dialogDeleteUser=true">
            <v-icon left>mdi-delete</v-icon>
            Excluir
          </v-btn>

          <v-btn outlined
                 :class="`${user.active? 'btn-inactive': 'btn-active'} mx-2`"
                 v-show="user.username !== '001'"
                 @click="dialogActiveInactive=true">
            <v-icon left>mdi-pencil</v-icon>
            <span v-if="user.active">Inativar</span><span v-else>Ativar</span>
          </v-btn>

          <v-btn
            :to="{ name: 'usersEdit', params: { id: user.id } }"
            class="btn-edit"
          >
            <v-icon left>mdi-pencil</v-icon>
            Editar
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-data-visualization">
          <v-row>
            <v-col md="6" sm="6" xs="12">
              <b>Nome:</b> {{ user.name }}
            </v-col>
            <v-col md="6" sm="6" xs="12">
              <b>E-mail:</b> {{ user.email }}
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4" sm="4" xs="12">
              <b>Usuário:</b> {{ user.username }}
            </v-col>
            <v-col md="4" sm="4" xs="12">
              <b>Telefone:</b> {{ user.phone }}
            </v-col>
            <v-col md="4" sm="6" xs="12">
              <b>Status:</b> {{ user.active | activeText}}
            </v-col>
          </v-row>
          <v-row>
            <v-col xs="12"><h3>Permissões</h3></v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-list-item>
                <v-list-item-content v-for=" permission in user.permissions" :key="permission.id">
                  <v-list-item-title>{{ permission.desc }}</v-list-item-title>
                  <v-list-item-subtitle>{{ permission.levelName }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>

        </v-card-text>
        <v-alert type="warning" outlined v-show="!user"
        >Nenhum cliente encontrado
        </v-alert
        >
        <confirm-dialog
          :dialog="dialogActiveInactive"
          :title="`${user.active? 'Inativar ': 'Ativar '} ${user.name}`"
          body="Tem certeza que deseja realizar essa ação?"
          @dialog-event="inactiveActive">
        </confirm-dialog>
        <confirm-dialog
          :dialog="dialogDeleteUser"
          :title="`Excluir  ${user.name}`"
          :body="`Tem certeza que deseja excluir o usuário ${user.name} ?`"
          @dialog-event="deleteUser">
        </confirm-dialog>

      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {mask} from "vue-the-mask";
import ConfirmDialog from '@/components/ConfirmDialog'

import {
  USER_ACTIVE, USER_DELETE,
  USER_GET, USER_INACTIVE,
} from "@/store/actions/user.type";
import {USER_RESET_STATE, SET_USER_ACTIVE} from "@/store/mutations/user.type";
import {CHANGE_PROGRESS} from "@/store/mutations/mutations.type";

export default {
  name: "user",
  directives: {mask},
  props: {
    id: {
      default: 0,
      type: Number,
      required: true
    }
  },

  components: {ConfirmDialog},
  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.dispatch(USER_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([
      await store.dispatch(USER_RESET_STATE),
      await store.dispatch(USER_GET, to.params.id)
    ]).then(() => {
      next();
    });
  },
  data: () => ({
    //Lista de Usuários
    pagination: {
      sortBy: "name"
    },
    search: "",
    selected: [],
    formIsValid: false,

    dialogActiveInactive: false,
    dialogDeleteUser: false
  }),
  methods: {
    pushRouteToView(route) {
      this.$router.push({name: route});
    },
    //Methods Lista Usuarios
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.dados.slice();
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    deleteUser(status) {
      this.dialogDeleteUser = false
      if (!status) {
        return
      }
      this.$store.commit(CHANGE_PROGRESS, true)
      this.$store
        .dispatch(USER_DELETE)
        .then(() => {

          this.$store.commit(CHANGE_PROGRESS, false)
          this.snackbar.snackbar = true;
          this.snackbar.color = "success";
          this.snackbar.text = "Excluído com sucesso";

          this.$router.push({
            name: "users",
          });
        })
        .catch(({response}) => {
          this.$store.commit(CHANGE_PROGRESS, false)
          this.snackbar.snackbar = true;
          this.snackbar.color = "error";
          this.snackbar.text = "Erro ao excluir.";
          // console.log(response.data);
          if (typeof response.data.message !== "undefined") {
            this.snackbar.text = response.data.message;
          }
        });
    },
    inactiveActive(status) {
      this.dialogActiveInactive = false
      if (!status) {
        return
      }
      let action = this.user.active ? USER_INACTIVE : USER_ACTIVE;
      this.$store.commit(CHANGE_PROGRESS, true)

      this.$store
        .dispatch(action, this.user.id)
        .then(() => {

          this.$store.commit(CHANGE_PROGRESS, false)
          this.snackbar.snackbar = true;
          this.snackbar.color = "success";
          this.snackbar.text = "Alterado com sucesso";
          this.$store.commit(SET_USER_ACTIVE, !this.user.active)
        })
        .catch(({response}) => {
          this.$store.commit(CHANGE_PROGRESS, false)
          this.snackbar.snackbar = true;
          this.snackbar.color = "error";
          this.snackbar.text = "Erro ao alterar.";
          // console.log(response.data);
          if (typeof response.data.message !== "undefined") {
            this.snackbar.text = response.data.message;
          }
        });
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["snackbar", "inProgress", "checkUsers", "user"])
  },
  watch: {}
};
</script>
